.btn-enabled {
  @apply py-1 w-20 h-10 rounded-sm bg-blue-300 hover:bg-blue-400;
}

.btn-disabled {
  @apply py-1 w-20 h-10 rounded-sm bg-blue-300 opacity-50;
}

.row-container {
  @apply my-8 px-2 h-10 flex justify-between;
}

.display-span {
  @apply inline-flex items-center overflow-x-auto rounded-sm flex-auto bg-gray-50;
}

.display-span::-webkit-scrollbar {
  height: 4px;
}

.display-span::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 2px;
}